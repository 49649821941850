import { GetServerSideProps } from 'next/types'
import { getTheatricalRelease } from 'src/services/Theatrical'
import { runExperimentOnServer } from '@/experimentation/server'
import { NextPageWithLayout } from '@/layouts'
import { TicketsLayout } from '@/layouts/TicketLayout'
import { getServerSideClient } from '@/services/ApolloClient'
import {
  getTheatricalTrailerExperiment,
  TheatricalTrailerExperimentType,
} from '@/services/CmsService/TheatricalTrailerExperiment'
import { contentfulFaqLocations } from '@/services/FaqService'
import { getGroupTicketOptionsBySlug } from '@/services/GroupTicketService'
import { getProjectGuildScore } from '@/services/ProjectsService'
import { getTheatricalPromo, TheatricalPromoType } from '@/services/Theatrical/contentfulQueries'
import { GroupTicketOption } from '@/types/codegen-contentful'
import { TheatricalReleaseObject, TheatricalReleaseRegion } from '@/types/codegen-federation'
import { getLocaleFromParams } from '@/utils/LocaleUtil'
import { getServerSidePropsErrorHandler } from '@/utils/nextUtils/nextErrorHandlers'
import { loadTranslations } from '@/utils/translate/translate-server'
import { ShowtimesView } from '@/views/TicketCheckoutViews/ShowtimesView'

interface TicketsPageProps {
  groupTicketOptions: GroupTicketOption[]
  theatricalRelease: TheatricalReleaseObject
  theatricalSlug: string
  trailerExperiment: TheatricalTrailerExperimentType
  promo?: TheatricalPromoType
  projectGuildScore: {
    guildScore: number | undefined
  }
  venueRefinementExperiment?: boolean
  groupTicketExperiment?: boolean
}

export const TicketsHypebotPage: NextPageWithLayout<TicketsPageProps> = ({
  groupTicketOptions,
  theatricalRelease,
  theatricalSlug,
  trailerExperiment,
  projectGuildScore,
  promo,
  venueRefinementExperiment,
  groupTicketExperiment,
}) => {
  return (
    <TicketsLayout
      shouldShowLanguageModal
      isDarkMode={true}
      useDefaultNav
      regions={theatricalRelease?.theatricalReleaseRegions as TheatricalReleaseRegion[]}
    >
      <ShowtimesView
        shouldUseDataDog
        promo={promo}
        trailerExperiment={trailerExperiment}
        theatricalRelease={theatricalRelease}
        theatricalSlug={theatricalSlug}
        groupTicketOptions={groupTicketOptions}
        projectGuildScore={projectGuildScore}
        venueRefinementExperiment={venueRefinementExperiment}
        groupTicketExperiment={groupTicketExperiment}
      />
    </TicketsLayout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ params, req, res, resolvedUrl }) =>
  getServerSidePropsErrorHandler({ resolvedUrl }, async ({ next404 }) => {
    const locale = getLocaleFromParams(params)
    const theatricalSlug = params?.slug
    if (!theatricalSlug || Array.isArray(theatricalSlug)) {
      return next404()
    }

    const url = req.url ? new URL(req.url, `http://${req.headers.host}`) : null
    const discounts = url?.searchParams.get('discounts')
    const promoCode = url?.searchParams.get('promo')
    const promoValue = (promoCode || discounts || '').toLowerCase()
    const isPromoEmpty = !promoValue

    const client = getServerSideClient({ locale, req, res })

    const [theatricalRelease, groupTicketOptions, projectGuildScore, trailerExperiment, promo, translations] =
      await Promise.all([
        getTheatricalRelease({ theatricalSlug, client }),
        getGroupTicketOptionsBySlug(theatricalSlug, contentfulFaqLocations.pif, {
          locale,
          preview: false,
        }),
        getProjectGuildScore(theatricalSlug),
        getTheatricalTrailerExperiment({ slug: theatricalSlug, locale, preview: false }),
        getTheatricalPromo({ slug: theatricalSlug, locale, preview: false }),
        loadTranslations(locale, ['common', 'home', 'tickets', 'chatbot', 'account']),
      ])

    const venueRefinementExperiment =
      (theatricalSlug === 'brave-the-dark' || theatricalSlug === 'rule-breakers') && isPromoEmpty
        ? await runExperimentOnServer('venue-refinement', false, req, res)
        : false

    const groupTicketExperiment = await runExperimentOnServer('group-tickets-pricing', false, req, res)

    return {
      props: {
        ...translations,
        theatricalSlug,
        theatricalRelease,
        locale,
        groupTicketOptions: groupTicketOptions?.groupTicketOptionCollection?.items ?? [],
        projectGuildScore: projectGuildScore.guildScore
          ? projectGuildScore
          : { ...projectGuildScore, guildScore: null },
        trailerExperiment,
        promo,
        venueRefinementExperiment,
        groupTicketExperiment,
      },
    }
  })

TicketsHypebotPage.getLayout = (page) => {
  return page
}

export default TicketsHypebotPage
